<template>
  <div style="width: 100%; height: 100%" id="service_request_con"></div>
</template>

<script>
import echarts from "echarts";
export default {
  name: "serviceRequests",
  mounted() {
    const _this = this;
    const serviceRequestCharts = echarts.init(
      document.getElementById("service_request_con")
    );
    serviceRequestCharts.setOption(_this.PolylineChartOption);
    window.addEventListener("resize", function () {
      serviceRequestCharts.resize();
    });

    _this.$nextTick(() => {
      setTimeout(() => {
        _this.renderChart();
      }, 500);
    });
  },
  watch: {
    HistoryOrder: function () {
      this.renderChart();
    },
  },
  methods: {
    renderChart() {
      const _this = this;
      if (_this.HistoryOrder) {
        _this.PolylineChartOption.series.map((item) => {
          if (item.name === "有效订单数量") {
            let list = [];
            _this.HistoryOrder.List.map((cell) => {
              list.push(cell.Count);
            });
            if (list.length === 7) {
              item.data = list;
              const serviceRequestCharts = echarts.init(
                document.getElementById("service_request_con")
              );
              serviceRequestCharts.setOption(_this.PolylineChartOption);
              window.addEventListener("resize", function () {
                serviceRequestCharts.resize();
              });
            }
          } else if (item.name === "订单总销售额") {
            let list = [];
            _this.HistoryOrder.List.map((cell) => {
              list.push(cell.Amount);
            });
            if (list.length > 0) {
              item.data = list;
              const serviceRequestCharts = echarts.init(
                document.getElementById("service_request_con")
              );
              serviceRequestCharts.setOption(_this.PolylineChartOption);
              window.addEventListener("resize", function () {
                serviceRequestCharts.resize();
              });
            }
          }
        });
      }
    },
  },
  computed: {
    PolylineChartOption() {
      return this.$store.state.home.IndexTempData.PolylineChartOption;
    },
    HistoryOrder() {
      return this.$store.state.home.IndexData.HistoryOrder;
    },
  },
};
</script>
